import React from 'react';
import WipPage from './wip';

const Notes = () => {
  return (
    <>
      <WipPage />

      {/* <p>
        Recalling a love for the past helps promote hope for the future, and reassures us that life
        is meaningful. <span>Alissa Roy </span>
      </p> */}
    </>
  );
};

export default Notes;
