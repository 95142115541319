import React, { useRef, useEffect } from 'react';
import '../styles/styles.scss';
import { Seo } from '../components/Seo';
import Footer from '../components/Footer';
import { blinkingEyesAnimation } from '../animations/blinking-eyes';

const WipPage = () => {
  const animRef = useRef(null);

  useEffect(() => {
    let lottie: any = null;
    const dynamicLottie = async () => {
      lottie = await import('lottie-web');
      const params = {
        name: 'blinkingEyes',
        container: animRef.current!,
        loop: true,
        autoplay: true,
        animationData: blinkingEyesAnimation,
      };
      lottie.loadAnimation(params);
    };

    dynamicLottie();
    return () => lottie?.destroy('blinkingEyes');
  }, []);

  return (
    <>
      <main id='wip'>
        <div className='wrapper'>
          <div className='lottie-container' ref={animRef}></div>
          <h1>Work in progress</h1>
          <p>
            Die Seite befindet sich noch in der Entwicklung, schaue gerne zu einem späteren
            Zeitpunkt noch einmal vorbei!
          </p>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default WipPage;

export const Head = () => <Seo title='Work in progress' />;
